<template>
  <div class="feedback">
    <h2>Feedback</h2>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdH8XQA5FT2Lg23KMYL2EPOqu3FrXHV58m1DIpIGBI4_rxAuw/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </div>
</template>

<script>

export default {
   created(){
        document.title = "Feedback | Musician Toolkit";    
    }
};
</script>

<style>
iframe {
  padding-top: 10px;
  align-content: center;
  }
</style>


