<template>
  <h2>The Artist Community</h2>
  <iframe src="https://open.spotify.com/embed/playlist/3Fomvo4Rb2yDglf54437jP" width="70%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
  <br>
  <br>
  <div><button @click="OpenPlaylist" id="comm-buttons">Check Out Our Playlist</button></div>
  <br>
  <div><button @click="JoinDiscord" id="comm-buttons">Join Our Discord</button></div>

</template>

<script>

export default {
  methods: {
    OpenPlaylist: function() {
         window.open("https://open.spotify.com/playlist/3Fomvo4Rb2yDglf54437jP?si=2r2YkG_hRf6n7xJiwiMBPQ");
    },
    JoinDiscord: function() {
        window.open("https://discord.gg/s4AseafZ6h");
    }

  },
   created(){
        document.title = "Community | Musician Toolkit"
    }
}
</script>

<style>
#comm-buttons {
   border-radius: 10px;
    background: transparent;
    border: 2px solid rgba(240, 175, 10, 0.838);
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
    font-weight: 600;
    width: 225px;
    height: 40px;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

#comm-buttons:hover {
  background: #b68f0f;
}
</style>