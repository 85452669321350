<template>
  <div class="login">
      <img src="../assets/mtklogo5.png" alt="Musician Toolkit Logo" id="logo">
      <p>Giving music artists the tools to <br> build their career</p>
      <br>
      <div id="signin-buttons-wrapper">
        <button  @click="googleSignIn" id="login-button"><i class="fab fa-google"></i>Sign in with Google</button>      
      </div>  
      <div>
           <button  @click="twitterSignIn" id="login-button"><i class="fab fa-twitter"></i>Sign in with Twitter</button> 
      </div>         
  </div>
</template>

<script>
import firebase from 'firebase';
export default {
  name: "SignUp",
  methods: {
    googleSignIn: function() {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(googleProvider).then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;
    var token = credential.accessToken;
    console.log(token);
    var user = result.user;
    console.log(user);
  }).catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    alert('There was an in isuue with signing in ' + errorCode + ' ' + errorMessage);
    var credential = error.credential;
    console.log(credential);

  })
    },
    twitterSignIn: function() {
        const twitterProvider = new firebase.auth.TwitterAuthProvider();
        firebase.auth().signInWithRedirect(twitterProvider).then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;
    var token = credential.accessToken;
    console.log(token);
    var user = result.user;
      console.log(user);
  }).catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    alert('There was an in isuue with signing in ' + errorCode + ' ' + errorMessage);
    var credential = error.credential;
    console.log(credential);
  })
    },
    appleSignIn: function () {
      const appleProvider = new firebase.auth.OAuthProvider("apple.com");
      firebase.auth().signInWithRedirect(appleProvider).catch((error) => {
        alert('There was an issue with signing in with your apple account ' + error);
      })

    }

  },
   created(){
        document.title = "Sign In | Musician Toolkit"
    }
};
</script>

<style>

.fab {
    padding-right: 10px;
}

#login-button {
    border-radius: 10px;
    background: transparent;
    border: 2px solid rgba(240, 175, 10, 0.838);
    color: white;
    margin-top: 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
    font-weight: 600;
    width: 225px;
    height: 40px;
    align-items: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;  
}

#login-button:hover {
  background: #b68f0f;
}

#logo {
    height: 256px;
    width: 320px;
    align-items: center;   
}

p {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    display: flexbox;
    font-weight: 700;
}

</style>